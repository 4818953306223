export default [
  // {
  //   id: "1",
  //   icon: "crosshair",
  //   title: "Dashboard",
  //   i18n: "MENU.DASHBOARD",
  //   to: "/dashboard/classic-dashboard",
  // },
  {
    id: '2',
    icon: 'maximize-2',
    title: 'Operações',
    i18n: 'MENU.OPERACOES',
    permissao: 'Operacao',
    to: '/operacoes',
  },
  {
    id: '3',
    icon: 'file',
    title: 'Títulos',
    i18n: 'MENU.TITULOS',
    permissao: 'Titulos',
    to: '/titulos',
  },
  {
    id: '4',
    icon: 'book',
    title: 'Programação',
    i18n: 'MENU.PROGRAMACAO',
    permissao: 'ProgramacaoPagamento',
    to: '/programacao',
  },
  {
    id: '5',
    icon: 'file-text',
    title: 'Relatórios',
    permissao: 'Relatorio',
    to: '/relatorio',
  },
  {
    id: '6',
    icon: 'trending-up',
    title: 'Arquivo de Retorno',
    i18n: 'MENU.ARQUIVO_RETORNO',
    permissao: 'ArquivoRetorno',
    to: '/arquivo-retorno',
  },
  {
    id: '7',
    icon: 'file-plus',
    title: 'Instruções',
    i18n: 'MENU.INSTRUCOES',
    permissao: 'Instrucoes',
    to: '/instrucoes',
  },
  {
    id: '8',
    icon: 'users',
    title: 'Usuários',
    permissao: 'Usuario',
    i18n: 'MENU.USUARIOS',
    to: '/usuarios',
  },
  {
    id: '9',
    icon: 'file',
    title: 'Títulos',
    i18n: 'MENU.TITULOS',
    permissao: 'Comercial',
    to: '/titulos-comercial',
  },
  {
    id: '10',
    icon: 'user-plus',
    title: 'Cadastro de Lead',
    permissao: 'Comercial',
    i18n: 'MENU.CADASTRO_LEAD',
    to: '/cadastro-lead',
  },
  {
    id: '11',
    icon: 'sliders',
    title: 'Visitas de Relacionamento',
    i18n: 'MENU.VISITAS_MANUTENCAO',
    permissao: 'Comercial',
    to: '/visita-relacionamento',
  },
  {
    id: '12',
    icon: 'smile',
    title: 'Relatório Cadastro',
    i18n: 'MENU.VISITAS_PROSPECCAO',
    permissao: 'Comercial',
    to: '/visita-prospeccao',
  },
  {
    id: '13',
    icon: 'shopping-bag',
    title: 'Desempenho comercial',
    i18n: 'MENU.DESEMPENHO_COMERCIAL',
    permissao: 'Comercial',
    to: '/desempenho-comercial',
  },
  {
    id: '14',
    icon: 'grid',
    title: 'Bitrix',
    i18n: 'MENU.BITRIX',
    permissao: 'Comercial',
    to: '/bitrix',
  },
  {
    id: '15',
    icon: 'award',
    title: 'Vadu',
    i18n: 'MENU.VADU',
    permissao: 'Comercial',
    to: '/vadu',
  },
  {
    id: '16',
    icon: 'settings',
    title: 'Configuracao',
    permissao: null,
    i18n: 'MENU.CONFIGURACOES',
    to: '/dashboard/classic-dashboard',
    childern: [
      {
        i18n: 'MENU.PERFIL',
        to: '/permissoes',
        permissao: 'Perfil',
      },
      {
        i18n: 'MENU.AUDITORIA',
        to: '/auditoria',
        permissao: 'Auditoria',
      },
      {
        i18n: 'MENU.COMUNICADO',
        to: '/comunicado',
        permissao: 'Comunicado',
      },
      {
        i18n: 'MENU.ROTULO_BOLETOS',
        to: '/rotulo-boletos',
        permissao: 'RotuloBoletos',
      },
    ],
  },
];
